import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { Spacer } from 'components';

// Self Component
import { Header, Packages, usePricingPageData } from 'sections/pricing';

// Reused Section
import { Cta } from 'components/section';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';

// Data
import { useKisPricingPageData } from 'graphqlHooks';

const Pricing = () => {
  const { seo, packages } = usePricingPageData();

  const { header: cmsHeader, packages: cmsPackages } = useKisPricingPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <Header data={cmsHeader} />
      <Spacer height={['38.4rem', null, '17.2rem', null, '27.9rem', '1rem']} />
      <Box as="main">
        <Packages data={cmsPackages} staticData={packages} />
        <Spacer height={['27.2rem', null, '26rem', null, '35.2rem', '30rem']} />
        <Cta />
        {/* TODO: THIS SPACER IS NOT CORRECT */}
        <Spacer height={['8rem', null, '8rem', null, '6.3rem', '7.1rem']} />
      </Box>
    </>
  );
};

export default injectIntl(Pricing);
