import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const usePricingPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: pricingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        packages {
          starterLabel
          plusLabel
        }
      }
      de: dePricingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        packages {
          starterLabel
          plusLabel
        }
      }
    }
  `);

  return data[locale];
};
