import React from 'react';

// External Components
import { Section, Paragraph, Box } from '@thepuzzlers/pieces';

// Local Components
import { MarkedText } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Packages = ({ data: { headline, packages }, staticData }) => {
  return (
    <Section id="pricing__packages">
      <Headline headline={headline} />
      {packages.map((data, index) => (
        <Package
          data={data.data}
          key={index}
          isFirstPackage={index === 0}
          staticData={staticData}
        />
      ))}
    </Section>
  );
};

const Headline = ({ headline }) => {
  return (
    <MarkedText
      as="h2"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.15,
        fontSize: ['3.2rem', null, '3.2rem', null, '3.2rem', '3.6rem'],
        gridColumn: [
          '1/13',
          null,
          '4/ span 16',
          null,
          '1/span 9',
          '12/ span 11'
        ],
        gridRow: [null, null, null, null, null, '1/ span 4'],
        alignSelf: [null, null, null, null, null, 'center']
      }}
      data={convertApiHtmlText(headline.html)}
    />
  );
};

const Package = ({
  data: { title, package_list },
  isFirstPackage,
  staticData
}) => {
  return (
    <Box
      className="__pricing-package"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '4/ span 17',
          null,
          '12/ span 12',
          '1/ span 8'
        ],
        mt: ['11.1rem', null, '9.2rem', null, 0, 0],
        '~ .__pricing-package': {
          mt: ['4rem', null, '6.4rem', null, '6.4rem', '4rem']
        }
      }}>
      {isFirstPackage ? (
        <PackageWithLabel title={title} staticData={staticData} />
      ) : (
        <PackageTitle title={title} />
      )}
      {package_list.map((data, index) => (
        <PackageListItem packageItem={data} key={index} />
      ))}
    </Box>
  );
};

const PackageWithLabel = ({ title, staticData }) => {
  return (
    <Box
      className="__package-title-with-label"
      sx={{
        display: 'flex',
        position: 'relative'
      }}>
      <PackageTitle title={title} />
      <Labels staticData={staticData} />
    </Box>
  );
};

const Labels = ({ staticData: { starterLabel, plusLabel } }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        right: ['0.1rem', null, '0.3rem', null, '0.2rem', '0.1rem'],
        bottom: ['0.6rem', null, '1.1rem', null, '0.7rem', '0.7rem']
      }}>
      <LabelItem text={starterLabel} />
      <LabelItem
        text={plusLabel}
        sx={{
          ml: ['2rem', null, '3.1rem', null, '3rem', '3.7rem']
        }}
      />
    </Box>
  );
};

const LabelItem = ({ text, sx }) => {
  return (
    <Paragraph
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1,
        letterSpacing: '0.02em',
        fontSize: ['1.3rem', null, '1.3rem', null, '1.3rem', '1.7rem'],
        textTransform: 'uppercase',
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        // this fix the issue with writing mode in firefox
        width: '1em',
        ...sx
      }}>
      {text}
    </Paragraph>
  );
};

const PackageTitle = ({ title }) => {
  return (
    <Paragraph
      className="__package-title"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        letterSpacing: '-0.01em',
        fontSize: ['2rem', null, '2.4rem', null, '2.2rem', '2rem'],
        flex: 1
      }}>
      {title}
    </Paragraph>
  );
};

const PackageListItem = ({ packageItem: { name, starter, plus } }) => {
  return (
    <Box
      className="__package-list-item"
      sx={{
        mt: '1.6rem',
        '~ .__package-list-item': {
          mt: '1.2rem'
        },
        display: 'flex',
        alignItems: 'center'
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1,
          letterSpacing: '-0.01em',
          fontSize: ['1.5rem', null, '1.8rem', null, '1.6rem', '1.6rem'],
          flex: 1
        }}>
        {name}
      </Paragraph>
      <Box
        sx={{
          display: 'flex'
        }}>
        <ChekMark isStarter isActive={starter} />
        <ChekMark
          isPlus
          isActive={plus}
          sx={{
            ml: ['1.7rem', null, '2.4rem', null, '2.4rem', '3.8rem']
          }}
        />
      </Box>
    </Box>
  );
};

const ChekMark = ({ sx, isActive, isPlus }) => {
  const color = isPlus ? 'darkPurple' : 'darkYellow';
  return (
    <Box
      className="__package-chek"
      sx={{
        width: ['1.6rem', null, '2rem', null, '1.8rem', '1.6rem'],
        height: ['1.6rem', null, '2rem', null, '1.8rem', '1.6rem'],
        borderRadius: '50%',
        bg: isActive ? color : null,
        border: ({ colors }) => `2px solid ${colors[color]}`,
        ...sx
      }}
    />
  );
};
