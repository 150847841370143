import React from 'react';

// External Components
import { Section, Box, Heading, Paragraph } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Local components
import {
  HeadlineBlock as HeadlineBlockDefault,
  ListDotOne,
  GridSpacer
} from 'components';

export const Header = ({
  data: {
    description,
    headline,
    page_title,
    plus_description,
    plus_do_list,
    plus_get_list,
    plus_price,
    plus_title,
    starter_description,
    starter_do_list,
    starter_get_list,
    starter_price,
    starter_title,
    user_do_title,
    user_get_title
  }
}) => {
  return (
    <Section as="header" id="pricing__header">
      <TopSpacer />
      <HeadlineBlock pageTitle={page_title} headline={headline} />
      <Description description={description} />
      <CardSpacer />
      <StarterCard
        title={starter_title}
        description={starter_description}
        userGetTitle={user_get_title}
        userDoTitle={user_do_title}
        getList={starter_get_list}
        doList={starter_do_list}
        price={starter_price}
      />
      <PlusCard
        title={plus_title}
        description={plus_description}
        userGetTitle={user_get_title}
        userDoTitle={user_do_title}
        getList={plus_get_list}
        doList={plus_do_list}
        price={plus_price}
      />
    </Section>
  );
};

// TODO: update the page_title to be headline and headline to be caption later in the cms
const HeadlineBlock = ({ pageTitle, headline }) => {
  return (
    <HeadlineBlockDefault
      headline={convertApiHtmlText(pageTitle.html)}
      caption={headline}
      sx={{
        gridColumn: [
          '1/13',
          null,
          '1/ span 10',
          null,
          '1/ span 8',
          '1/ span 10'
        ],
        mt: ['11rem', null, '14.5rem', null, 0, 0]
      }}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      className="__description"
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.5,
        letterSpacing: '-0.01em',
        fontSize: ['1.6rem', null, '1.8rem', null, '1.6rem', '1.8rem'],
        gridColumn: [
          '1/13',
          null,
          '1/ span 15',
          null,
          '1/ span 7',
          '1/ span 9'
        ],
        mt: ['1.4rem', null, '6.4rem', null, 0, '9.4rem'],
        alignSelf: [null, null, null, null, 'end']
      }}>
      {description}
    </Paragraph>
  );
};

const StarterCard = ({
  title,
  description,
  userGetTitle,
  userDoTitle,
  getList,
  doList,
  price
}) => {
  return (
    <PricingCard
      title={title}
      description={description}
      userGetTitle={userGetTitle}
      userDoTitle={userDoTitle}
      getList={getList}
      doList={doList}
      price={price}
      sx={{
        gridColumnStart: [1, null, 1, null, 9, 11],
        mt: ['6.7rem', null, 0, null, 0, 0],
        gridRow: [null, null, null, null, '2/ span 2', '2/ span 3']
      }}
    />
  );
};
const PlusCard = ({
  title,
  description,
  userGetTitle,
  userDoTitle,
  getList,
  doList,
  price
}) => {
  return (
    <PricingCard
      title={title}
      description={description}
      userGetTitle={userGetTitle}
      userDoTitle={userDoTitle}
      getList={getList}
      doList={doList}
      price={price}
      sx={{
        gridColumnStart: [1, null, 14, null, 17, 18],
        mt: ['5.9rem', null, 0, null, '7.1rem', '12.7rem'],
        gridRow: [null, null, null, null, '2/ span 3']
      }}
      isDark
    />
  );
};

// Reusable Components

const PricingCard = ({
  title,
  description,
  userGetTitle,
  userDoTitle,
  getList,
  doList,
  price,
  sx,
  isDark
}) => {
  return (
    <Box
      className="__pricing-card"
      sx={{
        gridColumnEnd: [13, null, 'span 11', null, 'span 8', 'span 7'],
        borderRadius: 'small',
        p: [
          '2.4rem 1.6rem 3.2rem 2.4rem',
          null,
          '2.4rem',
          null,
          '2.4rem 3.2rem 4rem 2.4rem',
          '2.4rem 3.4rem 2.4rem 2.4rem'
        ],
        bg: isDark ? 'darkPurple' : 'darkYellow',
        alignSelf: 'start',
        boxShadow: '0px 24px 24px rgba(0, 0, 0, 0.1)',
        ...sx
      }}>
      <CardTitle title={title} isDark={isDark} />
      <CardDescription description={description} isDark={isDark} />
      <ListBlock title={userGetTitle} list={getList} isDark={isDark} />
      <ListBlock title={userDoTitle} list={doList} isDark={isDark} />
      <CardPricing price={price} isDark={isDark} />
    </Box>
  );
};

const CardTitle = ({ title, isDark }) => {
  return (
    <Heading
      as="h3"
      className="__card-title"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1,
        fontSize: ['2.8rem', null, '2.8rem', null, '2.4rem', '3.2rem'],
        color: isDark ? 'neutralLightBeige' : 'neutralDark'
      }}>
      {title}
    </Heading>
  );
};

const CardDescription = ({ description, isDark }) => {
  return (
    <Paragraph
      className="__card-description"
      sx={{
        fontFamily: 'body.normal',
        lineHeight: 1.35,
        letterSpacing: '-0.01em',
        fontSize: ['1.5rem', null, '1.5rem', null, '1.4rem', '1.7rem'],
        mt: '0.4rem',
        minHeight: ['4rem', null, '4rem', null, '3.8rem', '4.6rem'],
        width: [null, null, null, null, '25rem', 'auto'],
        color: isDark ? 'neutralLightBeige' : 'neutralDark'
      }}>
      {description}
    </Paragraph>
  );
};

const ListBlock = ({ title, list, isDark }) => {
  return (
    <Box
      className="__list-block"
      sx={{
        mt: ['3.2rem', null, '2.4rem', null, '2.4rem', '3.2rem'],
        '~ .__list-block': {
          mt: ['3.2rem', null, '3.2rem', null, '3.2rem', '3.1rem']
        }
      }}>
      <Paragraph
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1.5,
          letterSpacing: '-0.01em',
          fontSize: ['1.5rem', null, '1.5rem', null, '1.4rem', '1.6rem'],
          color: isDark ? 'neutralLightBeige' : 'neutralDark'
        }}>
        {title}
      </Paragraph>
      <List list={list} isDark={isDark} />
    </Box>
  );
};

const List = ({ list, sx, isDark }) => {
  return (
    <Box
      className="__list"
      as="ul"
      sx={{
        mt: ['0.8rem', null, '0.8rem', null, '0.8rem', '0.9rem'],
        ...sx
      }}>
      {list.map((data, index) => (
        <ListItem data={data} key={index} index={index} isDark={isDark} />
      ))}
    </Box>
  );
};

const ListItem = ({ data: { text }, isDark }) => {
  return (
    <Box
      className="__list-item"
      as="li"
      sx={{
        display: 'flex',
        alignItems: 'center',
        ':not(:first-of-type)': {
          mt: ['1.2rem', null, '0.8rem', null, '0.8rem', '1.3rem']
        }
      }}>
      <ListDotOne
        sx={{
          width: ['1.1rem', null, '1.1rem', null, '1rem', '1.1rem'],
          color: isDark ? 'neutralLightBeige' : 'neutralDark'
        }}
      />
      <Paragraph
        as="span"
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.35,
          letterSpacing: '-0.01em',
          fontSize: ['1.5rem', null, '1.5rem', null, '1.4rem', '1.6rem'],
          flex: 1,
          ml: '1.6rem',
          color: isDark ? 'neutralLightBeige' : 'neutralDark'
        }}>
        {text}
      </Paragraph>
    </Box>
  );
};

const CardPricing = ({ price, isDark }) => {
  return (
    <Paragraph
      className="__card-pricing"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(price.html) }}
      sx={{
        fontFamily: 'body.normal',
        '& > span': {
          fontFamily: 'primary.bold'
        },
        lineHeight: 1,
        fontSize: ['2.8rem', null, '2.8rem', null, '2.8rem', '3.2rem'],
        mt: ['4.8rem', null, '4.8rem', null, '4.8rem', '3.3rem'],
        textAlign: 'right',
        color: isDark ? 'neutralLightBeige' : 'neutralDark'
      }}
    />
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, null, null, '16.8rem', '16.7rem']}
      sx={{
        display: ['none', null, 'none', null, 'block', 'block']
      }}
    />
  );
};

const CardSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '10rem', null, null, null]}
      sx={{
        display: ['none', null, 'block', null, 'none', 'none']
      }}
    />
  );
};
